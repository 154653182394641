export default [
  // 'General',
  // [
  //   {
  //     to: '/',
  //     icon: 'desktop-mac',
  //     label: 'Dashboard'
  //   }
  // ],
  // 'Examples',
  [
    {
      to: '/Reservation',
      label: 'Reservation',
      icon: 'calendar-clock'
    },
    {
      to: '/Reservation-en-attente',
      label: 'Reservations en attente',
      icon: 'calendar-clock'
    },
    {
      to: '/Personnel',
      label: 'Personnel',
      icon: 'account'
    },
    {
      to: '/Franchise',
      label: 'Franchise',
      icon: 'domain'
    },
    {
      to: '/Produit',
      label: 'Product',
      icon: 'tag'
    },
    {
      to: '/Service',
      label: 'Service',
      icon: 'hair-dryer'
    }
    // {
    //   to: '/forms',
    //   label: 'Forms',
    //   icon: 'square-edit-outline'
    // },
    // {
    //   to: '/profile',
    //   label: 'Profile',
    //   icon: 'account-circle'
    // },
    // {
    //   to: '/login',
    //   label: 'Login',
    //   icon: 'lock'
    // },
    // {
    //   label: 'Submenus',
    //   subLabel: 'Submenus Example',
    //   icon: 'view-list',
    //   menu: [
    //     {
    //       href: '#void',
    //       label: 'Sub-item One'
    //     },
    //     {
    //       href: '#void',
    //       label: 'Sub-item Two'
    //     }
    //   ]
    // }
  ]
]
