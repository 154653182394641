import axios from 'axios'
import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '@/views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    meta: {
      title: 'Franchise'
    },
    path: '/Franchise',
    name: 'Franchise',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tables" */ '@/views/franchise/index.vue')
  },
  {
    meta: {
      title: 'Nouveau Franchise'
    },
    path: '/Franchises/new',
    name: 'Franchises.new',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/franchise/new.vue')
  },
  {
    meta: {
      title: 'Modifier Franchise'
    },
    path: '/Franchises/:franchise',
    name: 'Franchises.edit',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/franchise/edit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Produit'
    },
    path: '/Produit',
    name: 'Produit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tables" */ '@/views/product/index.vue')
  },
  {
    meta: {
      title: 'Nouveau Produit'
    },
    path: '/Produits/new',
    name: 'Produits.new',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/product/new.vue')
  },
  {
    meta: {
      title: 'Modifier Produit'
    },
    path: '/Produits/:product',
    name: 'Produits.edit',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/product/edit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Service'
    },
    path: '/Service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "tables" */ '@/views/service/index.vue')
  },
  {
    meta: {
      title: 'Nouveau Service'
    },
    path: '/Services/new',
    name: 'Services.new',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/service/new.vue')
  },
  {
    meta: {
      title: 'Modifier Service'
    },
    path: '/Services/:service',
    name: 'Services.edit',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/service/edit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Personnel'
    },
    path: '/Personnel',
    name: 'Personnel',
    component: () => import(/* webpackChunkName: "tables" */ '@/views/personnel/index.vue')
  },
  {
    meta: {
      title: 'Nouveau Personnel'
    },
    path: '/Personnels/new',
    name: 'Personnels.new',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/personnel/new.vue')
  },
  {
    meta: {
      title: 'Modifier Personnel'
    },
    path: '/Personnels/:personnel',
    name: 'Personnels.edit',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/personnel/edit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Reservation'
    },
    path: '/Reservation',
    name: 'Reservation',
    component: () => import(/* webpackChunkName: "tables" */ '@/views/reservation/index.vue')
  },
  {
    meta: {
      title: 'Ajouter une Reservation'
    },
    path: '/Reservations/new',
    name: 'Reservations.new',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/reservation/new.vue')
  },
  {
    meta: {
      title: 'Accepter les réservations'
    },
    path: '/Reservation-en-attente',
    name: 'Reservation-en-attente',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/Reservation-en-attente/index.vue')
  },
  {
    meta: {
      title: 'Modifier Personnel'
    },
    path: '/Reservations/:personnel',
    name: 'Reservations.edit',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/reservation/edit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Forms'
    },
    path: '/forms',
    name: 'forms',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/FormsView.vue')
  },
  {
    meta: {
      title: 'Profile'
    },
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/ProfileView.vue')
  },
  {
    meta: {
      title: 'New Client'
    },
    path: '/client/new',
    name: 'client.new',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ClientFormView.vue')
  },
  {
    meta: {
      title: 'Edit Client'
    },
    path: '/client/:id',
    name: 'client.edit',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ClientFormView.vue'),
    props: true
  },
  {
    path: '/full-page',
    component: () => import(/* webpackChunkName: "full-page" */ '@/views/FullPageView.vue'),
    children: [
      {
        meta: {
          title: 'Login'
        },
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "full-page" */ '@/views/full-page/LoginView.vue')
      }
    ]
  }

]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (routeTo, routeFrom, next) => {
      const publicPages = ['/login']
      const authpage = !publicPages.includes(routeTo.path)
      const password = localStorage.getItem('password')
      const email = localStorage.getItem('email')
      const verified = await checkPassword(password, email)
      if (authpage && !verified) {
          return next('/login')
      }

      if (routeTo.name === 'login' && verified) {
          if (routeFrom.name) return next({ name: routeFrom.name })
          else return next({ name: 'Reservation' })
      }
      next()
})

export default router

async function checkPassword (password, email) {
  if (!password) return false
  const status = await axios.post('https://mb.4help.tn/api/v1/user/adminlogin', { password, email }).then(res => { return res.status })
  return (status === 200)
}

export function useRouter () {
  return router
}
